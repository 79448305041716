/* import only the necessary Bootstrap files */
@import "/src/styles/variables.scss";

/* #region --- customization ---- */

/* increase container width for the wider lg and xl breakpoints */
$container-max-widths: (
  lg: 1050px,
  xl: 1400px,
);
// these colors will be available for bg-{color name} and text-{color name}  example bg-success and text-info
$theme-colors: (
  "primary": $primaryColor,
  "accent": $accentColor,
  "success": $successColor,
  "info": $infoColor,
  "warning": $warnColor,
  "danger": $dangerColor,
  "light": $lightColor,
  "dark": $darkColor,
  "special": $specialColor,
  "error": $errorColor,
  "yellow": $yellow,
);

/* #endregion --- customization ------ */

/* finally, import Bootstrap to set the changes! */
@import "bootstrap";
