@use "@angular/material" as mat;
@use "../variables.scss" as *;
@use "../components";
@import "roboto-fontface/css/roboto/sass/roboto-fontface";

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($cteam-app-theme);
@include mat.all-component-typographies();

// Applies additional varients for the material theme using the usin descriptive name as
// success, danger, warning, info, light, dark to material components which variant mixins
// are created.

@include components.theme($cteam-app-theme, $cteam-app-varients);
@include mat.typography-hierarchy($cteam-app-theme);

.auto-select-control {
  position: relative;
  display: block;
  .mat-form-field-subscript-wrapper {
    padding: 0 1em;
    margin-top: 0px;

    .mat-mdc-form-field-error {
      display: none;
    }
  }
  &.auto-select-invalid .mat-form-field-subscript-wrapper {
    .mat-mdc-form-field-hint {
      display: none;
    }

    .mat-mdc-form-field-error {
      display: block;
    }
  }
}
