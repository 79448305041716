@use "@angular/material" as mat;
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

// https://material.io/design/color/the-color-system.html#tools-for-picking-colors
// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=002142
// https://javascript.plainenglish.io/angular-material-theming-system-complete-guide-3e52e9b986a6
// Pallets
$cteam-blue: (
  50: #e4e8ed,
  100: #bac6d5,
  200: #8fa1b8,
  300: #647d9c,
  400: #426389,
  500: #194a79,
  600: #114371,
  700: #053a66,
  800: #00315a,
  900: #002142,
  //Primary Color
  A100: #1d3c56,
  A200: #09283e,
  A400: #09283e,
  A700: #09283e,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue and text. Available color palettes: https://material.io/design/color/
// The app uses
$cteam-app-primary: mat.m2-define-palette($cteam-blue, 800, 500, 900);
$cteam-app-accent: mat.m2-define-palette(mat.$m2-yellow-palette, 700, 400, 900);

// The warn palette
$cteam-app-warn: mat.m2-define-palette(mat.$m2-yellow-palette, 900);
// These Pallets are used to integrate with Bootstrap color names.
$cteam-app-info: mat.m2-define-palette(mat.$m2-cyan-palette, 600);
$cteam-app-success: mat.m2-define-palette(mat.$m2-green-palette, 500);
$cteam-app-danger: mat.m2-define-palette(mat.$m2-red-palette);
$cteam-app-light: mat.m2-define-palette(mat.$m2-blue-gray-palette, 100);
$cteam-app-dark: mat.m2-define-palette(mat.$m2-blue-gray-palette, 800);
$cteam-app-special: mat.m2-define-palette(mat.$m2-purple-palette, 800);
$cteam-app-error: mat.m2-define-palette(mat.$m2-red-palette, 900);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$cteam-app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $cteam-app-primary,
      accent: $cteam-app-accent,
      warn: $cteam-app-danger,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);
// Create a varient map which describes pallets for different varient names
// this will allow themes to be created with descriptive names and bridge the gap
// between Material colors and Bootstrap colors
$cteam-app-varients: (
  warning: $cteam-app-warn,
  info: $cteam-app-info,
  success: $cteam-app-success,
  danger: $cteam-app-danger,
  light: $cteam-app-light,
  dark: $cteam-app-dark,
  special: $cteam-app-special,
  error: $cteam-app-error,
);

$primary: map-get($cteam-app-theme, primary);
$accent: map-get($cteam-app-theme, accent);
$warn: map-get($cteam-app-theme, warn);
$background: map-get($cteam-app-theme, background);
$foreground: map-get($cteam-app-theme, foreground);

$primaryColor: mat.m2-get-color-from-palette($primary);
$accentColor: mat.m2-get-color-from-palette($accent);

$successColor: mat.m2-get-color-from-palette($cteam-app-success);
$infoColor: mat.m2-get-color-from-palette($cteam-app-info);
$warnColor: mat.m2-get-color-from-palette($cteam-app-warn);
$dangerColor: mat.m2-get-color-from-palette($cteam-app-danger);
$lightColor: mat.m2-get-color-from-palette($cteam-app-light);
$darkColor: mat.m2-get-color-from-palette($cteam-app-dark);
$specialColor: mat.m2-get-color-from-palette($cteam-app-special);
$errorColor: mat.m2-get-color-from-palette($cteam-app-error);

$ng-select-highlight: mat.m2-get-color-from-palette($primary, text) !default;
$ng-select-primary-text: rgba(black, 0.87) !default;
$ng-select-primary-light-text: rgba(white, 0.87) !default;
$ng-select-secondary-text: rgba(black, 0.54) !default;
$ng-select-secondary-light-text: rgba(white, 0.54) !default;
$ng-select-disabled-text: rgba(black, 0.38) !default;
$ng-select-divider: rgba(black, 0.12) !default;
$ng-select-bg: #ffffff !default;

$roboto-font-path: "~roboto-fontface/fonts" !default;
