/**
The material color system has 3 color variants, primary, accent, and warn.
Bootstrap color system has 9 descriptive colors names
* primary
* secondary
* success
* danger
* warning
* info
* light
* dark
* white

Use this mixin to bridge the gap between material colors and bootstrap colors.
button-variants has been created as an example.
We can also theme overides for each of the material components

*/
@use "./button/variant" as button-variants;
@use "./formField/theme" as formField-theme;

@mixin theme($theme, $variants) {
  @include button-variants.color($theme, $variants);
  @include formField-theme.theme();
}
