@use "/src/styles/cteam_bootstrap.scss";
@use "/src/styles/themes/theme_cTeam.scss";
@use "/src/styles/variables.scss" as *;
@use "@angular/material" as mat;

@import "bootstrap/scss/mixins";

/* You can add global styles to this file, and also import other style files */

:root {
  --container-width: 1400px;
}
.container {
  max-width: var(--container-width);
}

/* Set the height of the app to 100% */
html,
body {
  height: 100%;
}
body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

svg {
  vertical-align: baseline;
}

@keyframes icon-spin {
  0% {
    animation-timing-function: cubic-bezier(0.5856, 0.0703, 0.4143, 0.9297);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.icon-spin {
  transform-origin: 50% 50%;
  transform-box: fill-box;
  animation: icon-spin 1s infinite linear;
}

// Todo: Remove.
.panel-banner {
  background: #002142;
  color: white;
  padding-top: 3px;
  padding-bottom: 3px;
}

.tab {
  margin-left: 10px;
  margin-right: 10px;
}

.report-checkbox {
  margin-right: 5px;
}

// TODO: Remove, Bootstrap flex-fill provides same function
.flexExpand {
  flex: 1 1 auto;
}

@font-face {
  font-family: "Sylfaen";
  src: local("Sylfaen"), url(./assets/fonts/sylfaen.ttf) format("truetype");
}

.custom-select {
  height: 59px;
  background-color: #fafafa;
}

.debug {
  margin: 1rem 0;
  padding: 0.5rem;
  background: #1f1f1f;
  color: #30b324;
  border: 2px solid black;
  max-height: 200px;
  overflow-y: scroll;
}

dl {
  dt {
    color: #6c757d;
    text-transform: uppercase;

    .mat-icon {
      width: 14px;
      height: 14px;
      font-size: 14px;
    }
  }
}

.review-container {
  padding: 20px;

  .review-header {
    color: $primaryColor;
    margin: 0 0 10px;
    font-size: 18px;
  }

  .data-row {
    line-height: 20px;
    padding: 6px 0;
    display: flex;
    align-items: stretch;

    .mat-icon {
      width: 14px;
      height: 14px;
      font-size: 14px;
    }

    .data-key {
      flex: 0 0 140px;
      align-items: center;
      display: flex;
      text-align: start;
      font-size: 0.6875rem;
      font-weight: 500;
      letter-spacing: 0.07272727em;
      line-height: 1rem;
      text-transform: uppercase;
      hyphens: auto;
      word-break: break-word;
      word-wrap: break-word;
      color: #5f6368;
      margin: 0;
      padding: 0;
      width: 100%;
    }

    .data-value {
      padding-left: 20px;
      flex: 1 1 auto;
      overflow: hidden;
      text-overflow: ellipsis;

      > div:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
}

.grid-collection {
  height: 100%;
  min-height: 600px;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
}

.grid-main {
  overflow-y: auto !important;
}

@mixin stack-review-container {
  &.review-container {
    .data-row {
      display: block;
    }

    .data-value {
      padding-left: 0;
    }
  }
}

.stack-rows {
  @include stack-review-container;
}

@include media-breakpoint-down(sm) {
  .stack-rows-sm {
    @include stack-review-container;
  }
}

@include media-breakpoint-down(md) {
  .stack-rows-md {
    @include stack-review-container;
  }
}

dl.horizontal-list {
  display: flex;
  flex-flow: row wrap;

  dt {
    flex-basis: 20%;
    padding: 2px 4px;
    text-align: right;
  }
  dd {
    flex-basis: 70%;
    flex-grow: 1;
    margin: 0;
    padding: 2px 4px;
  }
}

dl.no-list-wrap {
  dd {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

dl dt,
.data-label {
  color: #6c757d;
  text-transform: uppercase;
}

.mdc-floating-label--required.mat-mdc-form-field-required-marker {
  color: #f44336;
}

.mat-badge-icon {
  .mat-badge-content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;

    .mat-icon {
      height: 16px;
      width: 16px;
      line-height: 14px;
      font-size: 14px;
      --mdc-icon-button-icon-size: 16px;
    }
  }

  &.mat-badge-icon-hidden .mat-badge-content {
    display: none;
  }
}

.mat-icon {
  $icon-sizes: 12, 16, 18, 24, 36, 48, 64, 96, 128;
  @each $size in $icon-sizes {
    &.md-#{$size} {
      font-size: #{$size}px;
      height: #{$size}px;
      width: #{$size}px;
    }
  }
  &.citadel-logo {
    --logo-size: 40px;
    width: auto;
    height: var(--logo-size);
    &--vertical {
      --logo-size: 80px;
    }
  }
}

.mat-tooltip-multiline {
  height: auto;
  white-space: pre-line;

  .mdc-tooltip__surface {
    text-align: inherit;
    max-width: unset;
  }
}

.mat-mdc-list-base.bordered-list {
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.12);

  .mat-mdc-list-item {
    &:not(:first-child) .mat-mdc-list-item-content {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}

// For some reason, Angular Material believes that only mat-button and mat-stroked-button
// componnents deserve a hover affect.  We can make the hover affect a configurable
// paramater by adding the --opacity custom property to an element.

$opacity: 0.04;
.mat-mdc-button-base {
  // Material defins the opacity for mat-button and mat-stroked-button at 0.04
  &:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
    opacity: $opacity;
  }
}

// rocky linux chrome has a bug in it where it emits as if it is a touch device.
// This rule is written to be more specific than material buttons rules so the hover affect
// works on our dev boxes
@media (hover: none) {
  .mat-app-background .mat-mdc-button,
  .mat-mdc-outlined-button {
    &:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
      opacity: $opacity;
    }
  }
}
.mat-mdc-dialog-content {
  font-size: 14px;
}

.mat-mdc-button.button-small {
  font-size: 12px;
  min-width: 48px;
  line-height: 24px;
  padding: 0 10px;

  .mat-icon {
    font-size: 14px;
    height: 14px;
    width: 14px;
  }
}

.mat-mdc-card,
.mat-mdc-snack-bar-container {
  &.danger,
  &.warn,
  &.success,
  &.info {
    color: #fff;

    .mat-mdc-card-subtitle,
    .td-expansion-sublabel {
      color: rgba(255, 255, 255, 0.8);
    }

    .mat-mdc-card-actions {
      a {
        color: #fff;
      }
    }
    .mat-mdc-simple-snack-bar {
      justify-content: space-around;
    }
  }

  &.danger {
    background: $dangerColor;
  }

  &.warn {
    background: $warnColor;
  }

  &.success {
    background: $successColor;
  }

  &.info {
    background: $infoColor;
  }
}

.success-snackbar {
  text-align: center;
  color: white;
  --mdc-snackbar-container-color: #4caf50;

  .mat-simple-snackbar-action {
    color: white;
  }
}

.error-snackbar {
  text-align: center;
  color: white;
  --mdc-snackbar-container-color: #f44336;

  .mat-simple-snackbar-action {
    color: white;
  }
}

.info-snackbar {
  text-align: center;
  color: white;
  --mdc-snackbar-container-color: #00acc1;

  .mat-simple-snackbar-action {
    color: white;
  }
}

.warn-snackbar {
  text-align: center;
  color: black;
  --mdc-snackbar-container-color: #fdd835;

  .mat-simple-snackbar-action {
    color: black;
  }
}

// TODO: Add to Material Theming
// This will darken the background slightly. Which makes white elements standout more.
.mat-app-background {
  background-color: #f5f5f5; //whitesmoke
}
/*
 Z-indexing is tricky.  As a general guide here is how we can manage z-indexing
 1. Material overlays will place a z-indes of 1000 on each of its overlys such as material dialog and mat menues.  This allows us to utilize z indexes less than 1000 to position elements.
 2. Application componets
  The z index for components can be between 0 and 10 inclusive. This will allow the stacking of elements inside our components upto 11 elements high.
 3. Application Toolbar, top-acion bars and bottom-action bars
  The man app toolbar and the bottom-action bar will have a z-index of 20. this will ensure that application components will render under these elements.
  The top-action bar will need to be above the application components, but below the Application Toolbar, therefore its z-index can be 15.

*/
.app-toolbar,
.bottom-action {
  z-index: 20;
}
.top-action {
  z-index: 15;
}

// utilitiy classes for sticky-top and sticky bottom.  They have a ct prefix so they do not clash whith bootstrap utility classes.
.ct-sticky-bottom {
  position: sticky;
  bottom: 0;
}
.ct-sticky-top {
  position: sticky;
  top: 0;
}
// This is needed to overide the mat-menu maximum size.
.menu--main {
  max-width: 320px !important;
  min-width: 320px !important;
}

a.mat-mdc-menu-item {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

// Utility
.gap-1 {
  gap: 0.5ch;
}
.far-add-fv-menu {
  min-width: 600px !important;
}

// Highlight class used by appHighlight directive
.mat-mdc-option {
  .highlighted {
    font-weight: bold;
    text-decoration: underline;
  }
}

th,
td {
  text-align: center !important;
}

div.mat-sort-header-container {
  justify-content: center;
}

//Inherited Badge Indicator
.inheritedFromFlag {
  background-color: purple;
  color: white;
  float: left;
  margin-right: 3px;
}

.bg-machine-red {
  background: linear-gradient(135deg, #f44336 50%, #00acc1 50%);
}

.inheritedBadgeColor {
  background-color: purple;
  color: white;
}

// REFACTOR: move to themes
.mat-mdc-dialog-content {
  font-size: 14px;
}

// Phone Number Refactor - Remove Arrows from Number Fields
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.mat-mdc-menu-panel.screening-events {
  max-width: unset;
}

// fv-select updates
.fv-select {
  .mat-mdc-option {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .mdc-list-item__primary-text {
    flex: 1;
    overflow: hidden;
  }
}

.form-inputs-read-only {
  .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow,
  .mat-mdc-form-field-required-marker {
    display: none;
  }

  .mat-mdc-select-disabled,
  .mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
    color: var(--mdc-outlined-text-field-input-text-color);
  }
}

.mat-mdc-table.header-primary-color {
  .mat-mdc-header-cell {
    background: mat.m2-get-color-from-palette($primary);
    color: mat.m2-get-color-from-palette($primary, default-contrast);
  }

  .mat-sort-header-arrow {
    color: mat.m2-get-color-from-palette($primary, default-contrast);
  }
}

.panel-200-mh .mat-expansion-panel-content .mat-expansion-panel-body {
  max-height: 200px !important;
  overflow: auto !important;
  font-size: 12px;
}

.panel-250-mh .mat-expansion-panel-content .mat-expansion-panel-body {
  max-height: 250px !important;
  overflow: auto !important;
  font-size: 12px;
}

.header-box .mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background {
  border-color: lightgrey !important;
  background-color: transparent;
}
